import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import axios from 'axios';

const MONTHLY_LEASE_FEE_URL = '/shop/' // TODO

export default function MonthlyLeaseFeeModal({shop, hide, onSave}) {

    const [monthlyLeaseFee, setMonthlyLeaseFee] = useState('');

    useEffect(_ => { setMonthlyLeaseFee(shop.monthlyLeaseFee || '') }, [shop])

    const saveMonthlyLeaseFee = _ => {
        // TODO: Validate
        let fee = parseFloat(monthlyLeaseFee);
        axios.put(MONTHLY_LEASE_FEE_URL + shop._id + '/monthlyLeaseFee', fee, { headers: { "Content-Type": "text/plain" } }).then(_ => {
            onSave();
            hide();
        })
    }

    return <Modal show={true} onHide={hide} centered>
        <Modal.Header closeButton>
            <Modal.Title>Edit Shop Lease Fee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Group controlId="monthlyLeaseFee">
                <Form.Label>Monthly Lease Fee</Form.Label>
                <InputGroup className="mb-2">
                    <InputGroup.Prepend><InputGroup.Text>$</InputGroup.Text></InputGroup.Prepend>
                    <Form.Control value={monthlyLeaseFee} type="number" placeholder="1000" onChange={e => setMonthlyLeaseFee(parseFloat(e.target.value))} />
                </InputGroup>
                <Form.Text className="text-muted">Enter a Dollar Amount to be paid Monthly</Form.Text>
            </Form.Group>

            <Modal.Footer>
                <Button variant="secondary" onClick={hide}>Cancel</Button>
                <Button disabled={!monthlyLeaseFee} variant="primary" onClick={saveMonthlyLeaseFee}>Save</Button>
            </Modal.Footer>
        </Modal.Body>
    </Modal>
}
