import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import axios from 'axios';

const SECURITY_FOOTAGE_URL = '/security';
const TERMINALS_URL = '/terminals';


// !TODO: temporary static terminal dropdown
const TERMINALS = [
    {
        key: 'marina-k1-t1',
        label: 'Marina Kiosk 1 Terminal 1',
        shopId: 'marina'
    },
    {
        key: 'marina-k1-t2',
        label: 'Marina Kiosk 1 Terminal 2',
        shopId: 'marina'
    },
    {
        key: 'marina-k1-t3',
        label: 'Marina Kiosk 1 Terminal 3',
        shopId: 'marina'
    },
    {
        key: 'marina-k1-t4',
        label: 'Marina Kiosk 1 Terminal 4',
        shopId: 'marina'
    },
    {
        key: 'eagleEyeNapa-k1-t1',
        label: 'Eagle Eye Napa Kiosk 1 Terminal 1',
        shopId: 'marina'
    },
    {
        key: 'eagleEyeNapa-k1-t2',
        label: 'Eagle Eye Napa Kiosk 1 Terminal 2',
        shopId: 'marina'
    },
    {
        key: 'eagleEyeNapa-k1-t3',
        label: 'Eagle Eye Napa Kiosk 1 Terminal 3',
        shopId: 'marina'
    },
    {
        key: 'eagleEyeNapa-k1-t4',
        label: 'Eagle Eye Napa Kiosk 1 Terminal 4',
        shopId: 'marina'
    },
    {
        key: 'vhhc-k1-t1',
        label: 'VHHC Kiosk 1 Terminal 1',
        shopId: 'vhhc'
    },
    {
        key: 'vhhc-k1-t2',
        label: 'VHHC Kiosk 1 Terminal 2',
        shopId: 'vhhc'
    },
    {
        key: 'vhhc-k1-t3',
        label: 'VHHC Kiosk 1 Terminal 3',
        shopId: 'vhhc'
    },
    {
        key: 'vhhc-k1-t4',
        label: 'VHHC Kiosk 1 Terminal 4',
        shopId: 'vhhc'
    },
    {
        key: 'royalHealingEmporium-k1-t1',
        label: 'Royal Healing Emporium T1',
        shopId: 'royalHealingEmporium'
    },
    {
        key: 'royalHealingEmporium-k1-t2',
        label: 'Royal Healing Emporium T2',
        shopId: 'royalHealingEmporium'
    },
    {
        key: 'royalHealingEmporium-k1-t3',
        label: 'Royal Healing Emporium T3',
        shopId: 'royalHealingEmporium'
    },
    {
        key: 'royalHealingEmporium-k1-t4',
        label: 'Royal Healing Emporium T4',
        shopId: 'royalHealingEmporium'
    },
    {
        key: 'higherPath-k1-t1',
        label: 'The Higher Path T1',
        shopId: 'higherPath'
    },
    {
        key: 'higherPath-k1-t2',
        label: 'The Higher Path T2',
        shopId: 'higherPath'
    },
    {
        key: 'higherPath-k1-t3',
        label: 'The Higher Path T3',
        shopId: 'higherPath'
    },
    {
        key: 'higherPath-k1-t4',
        label: 'The Higher Path T4',
        shopId: 'higherPath'
    }
]


export default function SecurityFootage(props) {

    const [videos, setVideos] = useState(null);

    const [videoUrl, setVideoUrl] = useState(null);
    const [terminalId, setTerminalId] = useState(TERMINALS[0].key);

    const [terminals, setTerminals] = useState([]);

    useEffect(() => {
        axios.get(`${SECURITY_FOOTAGE_URL}/${terminalId}`).then(({ data }) => {
            console.log(data)
            setVideos(data);
            // if (data[0] && data[0].mp4) setVideoUrl(data[0].mp4)
        }).catch(console.error)
    }, [terminalId]);

    useEffect(() => {
        axios.get(TERMINALS_URL).then(({ data }) => {
            console.log(data);
            setTerminals(TERMINALS)
            // setTerminals(data.map(t => ({key: t._id, label: t.name, shopId: t.shopId})))
            // if (data[0] && data[0].mp4) setVideoUrl(data[0].mp4)
        }).catch(console.error)
    }, []);


    let securityEventCards = videos && videos[0] && videos.map((v, idx) => (
        <div key={idx} style={{ display: 'inline-block', float: 'none', width: '280px', padding: '10px' }}>
            <Card onClick={_ => setVideoUrl(v.mp4)}>
                <Card.Header>
                    {v.key}
                </Card.Header>
                {/* <h4 style={{ position: 'absolute', top: '45px', color: '#26A65B', fontWeight: 'bold', }}>{String.fromCharCode(65+idx)}</h4> */}
                <Card.Body>
                    <Card.Img src={v.jpg}/>
                </Card.Body>
            </Card>
        </div>
    ))


    return (
        <div className="mt-3">
            
            <Form.Group controlId="terminal-select">
                        <Form.Label>Terminal</Form.Label>
                        <Form.Control as="select" onChange={e => setTerminalId(e.target.value)}>
                            {terminals.map((opt, idx) => <option key={idx} value={opt.key}>{opt.label}</option>)}
                        </Form.Control>
                    </Form.Group>
            <div>
                <video src={videoUrl} width="640" height="480" controls>
                </video>
            </div>
            <div style={scrollableRow}>
                { securityEventCards }
            </div>
        </div>
    )
}

const scrollableRow = {
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    display: 'block',
    padding: '5px',
    margin: 0
}