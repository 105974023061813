import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import axios from 'axios';

let SERVICE_RATE_URL = '/shop/' // TODO

export default function ServiceRateModal({shop, hide, onSave}) {

    const [rate, setRate] = useState('');

    useEffect(_ => { setRate(shop.serviceRate*100 || '') }, [shop])

    const saveServiceRate = _ => {
        // TODO: Validate
        let serviceRate = parseFloat(rate)/100;
        axios.put(SERVICE_RATE_URL + shop._id + '/serviceRate', serviceRate, { headers: { "Content-Type": "text/plain" } }).then(_ => {
            onSave();
            hide();
        })
    }

    return <Modal show={true} onHide={hide} centered>
        <Modal.Header closeButton>
            <Modal.Title>Edit Shop Service Rate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Group controlId="rate">
                <Form.Label>Rate</Form.Label>
                <InputGroup className="mb-2">
                    <InputGroup.Prepend><InputGroup.Text>%</InputGroup.Text></InputGroup.Prepend>
                    <Form.Control value={rate} type="number" placeholder="5" onChange={e => setRate(parseFloat(e.target.value))} />
                </InputGroup>
                <Form.Text className="text-muted">Enter a percentage between 0 and 100</Form.Text>
            </Form.Group>

            <Modal.Footer>
                <Button variant="secondary" onClick={hide}>Cancel</Button>
                <Button disabled={!rate} variant="primary" onClick={saveServiceRate}>Save</Button>
            </Modal.Footer>
        </Modal.Body>
    </Modal>
}