import React, { useState } from 'react';
import ReactTable from 'react-table-6';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { toast } from 'react-toastify';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';

const SOLD_PRODUCTS_SEARCH_URL = '/searchSoldProducts';

export default function SalesByProduct(props) {

    const [search, setSearch] = useState('');
    const [matchingProductSales, setMatchingProductSales] = useState([]);

    const searchSoldProducts = term => axios.post(SOLD_PRODUCTS_SEARCH_URL, {term: search}).then(({data}) => {
        setMatchingProductSales(data);
    }).catch(_ => toast.error("Error sending search"))


    console.log('hey', search)

    const columns = [
        {
            Header: '',
            accessor: 'img',
            width: 50,
            Cell: c => <img style={{height: '50px', width: '50px'}} src={c.value} alt={'product img'}/>,
            aggregate: vals => vals.reduce((allPaid, invoicePaid) => allPaid && invoicePaid, true)
        },
        {
            Header: 'Name',
            accessor: 'name'
        },
        {
            Header: 'Quantity Sold',
            accessor: 'quantity'
        },
        {
            Header: '$ Sales',
            accessor: r => `$${r.sales.toFixed(2)}`,
            id: 'sales'
        },
        {
            Header: 'Shops',
            accessor: 'shopIds'
        }
    ]

    let totalQuantity = matchingProductSales.reduce((total, p) => p.quantity + total, 0);
    let totalSales = matchingProductSales.reduce((total, p) => p.sales + total, 0);
    let topSellingProduct = matchingProductSales.reduce((topSeller, p) => p.quantity > topSeller.quantity ? p : topSeller, matchingProductSales[0])
    
    return (
        <div className="m-5">
                <input onChange={e => setSearch(e.target.value)}/>
                <Button onClick={_ => searchSoldProducts(search)}>Search</Button>
                <Row className="my-3">
                    <Col md={3}>
                        <Card>
                            <Card.Header className="pt-1 pb-0"><h3>Quantity</h3></Card.Header>
                            <Card.Body style={{height: '70px' }}><h2 className="text-success">{totalQuantity}</h2></Card.Body>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card>
                            <Card.Header className="pt-1 pb-0"><h3>Sales</h3></Card.Header>
                            <Card.Body style={{height: '70px' }}><h2 className="text-success">${totalSales.toFixed(2)}</h2></Card.Body>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card>
                            <Card.Header className="pt-1 pb-0"><h3>SKU's</h3></Card.Header>
                            <Card.Body style={{height: '70px' }}><h2 className="text-success">{matchingProductSales.length}</h2></Card.Body>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card>
                            <Card.Header className="pt-1 pb-0"><h3>Top Selling</h3></Card.Header>
                            <Card.Body style={{height: '70px' }} className="p-3"><h6 className="text-success">{topSellingProduct ? topSellingProduct.name : 'none'}</h6></Card.Body>
                        </Card>
                    </Col>
                </Row>
                <ReactTable
                    data={matchingProductSales} 
                    columns={columns}
                    className='-striped -highlight table'
                    defaultPageSize= {15}
                    pageSizeOptions= {[8, 10, 20]}
                    />
        </div>
    )
}
