import React, { useState } from 'react';
import ReactTable from 'react-table-6';
import useTerminals from '../hooks/useTerminals';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import useShops from '../hooks/useShops';
import axios from 'axios';
import { toast } from 'react-toastify';

export default function Kiosks(props) {
    const [terminals, refreshTerminals] = useTerminals();
    const [shops] = useShops();

    const [showCreateModal, setShowCreateModal] = useState();
    const [disabled, setDisabled] = useState(false);
    const [maxProducts, setMaxProducts] = useState(19);
    const [shopId, _setShopId] = useState('');
    const [kioskNum, _setKioskNum] = useState('');
    const setShopId = id => {
        _setKioskNum('')
        _setShopId(id)
    }
    const setKioskNum = num => {
        if (num && parseInt(num)) _setKioskNum(parseInt(num));
        else if (!num) _setKioskNum('');
    }

    console.log(shopId, kioskNum, maxProducts)
    const createKiosk = _ => {
        setDisabled(true);
        axios.post('/kiosk', {
            shopId,
            maxProducts,
            kioskNum
        }).then(resp => {
            setShowCreateModal(false);
            refreshTerminals();
        }).catch(e => {
            toast.error('failed to create kiosk');
            setShowCreateModal(false);
            refreshTerminals();
        });
    };

    const columns = [
        {
            Header: '_id',
            accessor: '_id'
        },
        {
            Header: 'Kiosk',
            accessor: 'kioskName'
        },
        {
            Header: 'KioskId',
            accessor: 'kioskId'
        },
        {
            Header: 'Name',
            accessor: 'name'
        },
        {
            Header: 'ShopId',
            accessor: 'shopId'
        },
        {
            Header: 'Status',
            accessor: 'status'
        }
    ]
    return (
        <div>
            <Modal show={showCreateModal} onHide={_ => setShowCreateModal(false)}>
                <Modal.Header>
                    <h3 className="text-success">Create Kiosk</h3>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Num Slots</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend><InputGroup.Text>#</InputGroup.Text></InputGroup.Prepend>
                            <Form.Control type="number" step="1" value={maxProducts} onChange={e => setMaxProducts(parseInt(e.target.value))}/>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>ShopId</Form.Label>
                        <Form.Control as="select" defaultValue={''} onChange={e => setShopId(e.target.value)}>
                            <option key="" value="">---</option>}
                            { shops.map(shop => <option key={shop._id} value={shop._id}>{shop._id}</option>)}
                        </Form.Control>
                    </Form.Group>
                    { shopId &&
                        <Form.Group>
                            <Form.Label>KioskId</Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend><InputGroup.Text>{shopId}-k</InputGroup.Text></InputGroup.Prepend>
                                <Form.Control type="number" step="1" value={kioskNum} onChange={e => setKioskNum(e.target.value)}/>
                            </InputGroup>
                        </Form.Group>
                    }
                    {
                        shopId && kioskNum && maxProducts && <Button disabled={disabled} onClick={createKiosk}>Create</Button>
                    }
                </Modal.Body>
            </Modal>
            <Button onClick={_ => setShowCreateModal(true)}>Create Kiosk</Button>
            <ReactTable
                data={terminals} 
                pivotBy={['kioskId']}
                columns={columns}
                className='-striped -highlight table'
                defaultPageSize= {8}
                pageSizeOptions= {[8, 10, 20]}
                />
        </div>
    )
}
