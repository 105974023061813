import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import axios from 'axios';

let SHOP_URL = '/shop'

const checkAlphaNumeric = str => {
    if (typeof str === 'string') {
        let code = str.charCodeAt(0);
        return ((code > 47 && code < 58) || // numeric (0-9)
            (code > 64 && code < 91) || // upper alpha (A-Z)
            (code > 96 && code < 123)) // alpha (a-z)
    } else {
        return true;
    }
}


const INVENTORY_MANAGEMENT_CHOICES = ['', 'greenstop', 'blaze', 'treez']

export default function CreateShopModal({hide, onSave}) {

    const [shopId, setShopId] = useState('');
    const [name, setName] = useState('');
    const [ownerName, setOwnerName] = useState('');
    const [email, setEmail] = useState('') ;
    const [inventoryManagement, setInventoryManagement] = useState('') ;


    const saveShop = _ => {
        // TODO: Validate
        axios.post(SHOP_URL, {
            shopId,
            name,
            ownerName,
            email,
            inventoryManagement
        }).then(_ => {
            onSave();
            hide();
        })
    }


    const disabled = !(shopId && name && ownerName && email)

    return <Modal show={true} onHide={hide} centered>
        <Modal.Header closeButton>
            <Modal.Title>Create Shop</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Group controlId="shopId">
                <Form.Label>shopId</Form.Label>
                <Form.Control value={shopId} type="text" placeholder="shopId" onChange={e => checkAlphaNumeric(e.nativeEvent.data) && setShopId(e.target.value)} />
                <Form.Text className="text-muted">Only alphanumeric characters ie. abc123</Form.Text>
            </Form.Group>
            <Form.Group controlId="name">
                <Form.Label>Shop Name</Form.Label>
                <Form.Control type="text" placeholder="Shop Name" onChange={e => setName(e.target.value)} />
            </Form.Group>
            <Form.Group controlId="ownerName">
                <Form.Label>Owner Name</Form.Label>
                <Form.Control type="text" placeholder="Primary Contact" onChange={e => setOwnerName(e.target.value)} />
            </Form.Group>
            <Form.Group controlId="email">
                <Form.Label>Owner Email</Form.Label>
                <Form.Control type="email" placeholder="abc@def.com" onChange={e => setEmail(e.target.value)} />
            </Form.Group>
            <Form.Group controlId="inventoryManagement">
                <Form.Label>Inventory Management</Form.Label>
                <Form.Control as="select" value={inventoryManagement || ''} onChange={e => setInventoryManagement(e.target.value)}>
                    { INVENTORY_MANAGEMENT_CHOICES.map(choice => <option key={choice} value={choice}>{choice}</option>) }
                </Form.Control>
            </Form.Group>
            

            <Modal.Footer>
                <Button variant="secondary" onClick={hide}>Cancel</Button>
                <Button disabled={disabled} variant="primary" onClick={saveShop}>Create</Button>
            </Modal.Footer>
        </Modal.Body>
    </Modal>
}