
import { useState, useEffect } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import Button from 'react-bootstrap/esm/Button';

const generateSearchResult = (product, searchStr) => {
    product['searchIndex'] = product.name.toUpperCase().search(searchStr.toUpperCase());
    if (product.sku && product['searchIndex'] < 0) {
        product['searchIndex'] = product.sku.toUpperCase().search(searchStr.toUpperCase());
    }
    if (product.locationName && product.locationName === searchStr) product['searchIndex'] = 0;
    return product;
}

export default function CreateProductFeature({ shopId, onClose, afterSave }) {
    const [allProducts, setAllProducts] = useState([]);
    const [search, setSearch] = useState('')
    const [selectedProduct, setSelectedPrduct] = useState();
    const [impressions, setImpressions] = useState(0);
    const [banner, setBanner] = useState(false);
    const [popup, setPopup] = useState(false);
    const [preorderBanner, setPreorderBanner] = useState(false);
    const [preorderPopup, setPreorderPopup] = useState(false);

    useEffect(_ => {
        axios.get(`/shop/${shopId}/products`).then(resp => {
            setAllProducts(resp.data)
        })
    }, [shopId])

    const saveFeature = _ => {
        axios.post('/featuredProducts', {
            shopId,
            productId: selectedProduct._id,
            productName: selectedProduct.name,
            impressions,
            banner,
            popup,
            preorderBanner,
            preorderPopup,
            views: 0,
            active: true
        }).then(resp => resp.data).then(_ => {
            afterSave();
            onClose();
        })
    }

    let products = allProducts.map(p => generateSearchResult(p, search))
        .filter(p => p.searchIndex >= 0)
        .sort((p, q) => p.searchIndex >= q.searchIndex )

    return <Modal show={true} size="xl" onHide={onClose}>
        <Modal.Header closeButton={true}>
            <h2>{ shopId } { selectedProduct && `- ${selectedProduct.name}`}</h2>
        </Modal.Header>
        { !selectedProduct ?
            <Modal.Body>
                <Form.Control placeholder="Search" onChange={e => setSearch(e.target.value)}/>
                <ListGroup className="mt-2">
                    { products.map( (p, idx) =>
                        <ListGroup.Item key={idx} onClick={_ => setSelectedPrduct(p)}>
                            {p.name}
                        </ListGroup.Item>
                    ) }
                </ListGroup>
                {!products.length && <span className="text-danger">No Products Avalable for Selected Shop</span>}
            </Modal.Body> :
            <Modal.Body>
                <Form.Label>Impressions:</Form.Label>
                <Form.Control type="number" onChange={e => setImpressions(parseInt(e.target.value))}/>
                <Form.Check onChange={e => setBanner(e.target.checked)} label="Banner"/>
                <Form.Check onChange={e => setPopup(e.target.checked)} label="Popup"/>
                <Form.Check onChange={e => setPreorderBanner(e.target.checked)} label="Pre-Order Banner"/>
                <Form.Check onChange={e => setPreorderPopup(e.target.checked)} label="Pre-Order Popup"/>
            </Modal.Body>
        }
        { !!impressions && (banner || popup || preorderBanner || preorderPopup) &&
            <Modal.Footer>
                <Button className="float-right" onClick={saveFeature}>Save</Button>
            </Modal.Footer>
        }
    </Modal>
}
