import { useState, useEffect } from 'react';
import axios from 'axios';

const ORDERS_URL = '/orders';

export default function useOrders(query) {

    const [orders, setOrders] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const refreshTerminals = _ => setRefresh(!refresh)

    useEffect(() => {
        axios.post(ORDERS_URL, query || {}).then(({ data }) => {
            if (data && data.length) setOrders(data);
            else setOrders([]);
        })
    }, [query, refresh]);


    return [orders, refreshTerminals]
}
