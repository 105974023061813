let auth0Config = {
    "domain": "greenstop-god-dev.us.auth0.com",
    "clientId": "ASx7mpPgLG5AzyA3MhOYTUCtButlTwou",
    "audience": "https://dev.god.smart-dispensary.com/api"
}

const ENV_SLUG = window.origin.split('.')[0].split('//')[1];
if (ENV_SLUG === 'god') {
    auth0Config = {
        "domain": "greenstop-god.us.auth0.com",
        "clientId": "XtPhHz5EXR66R5oGpxLOfwThGcGrXygB",
        "audience": "https://god.smart-dispensary.com/api"
    }
}

export default auth0Config;