import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { toast } from 'react-toastify';

let SHOP_URL = '/shop/' // TODO

export default function TransactionFeeModal({shop, hide, onSave}) {

    const [feeConfig, setFeeConfig] = useState([{start: 0, fee: 0}]);
    const updateConfig = (idx, start, fee) => {
        let newFeeConfig = [...feeConfig];
        newFeeConfig[idx] = { start , fee: fee || 0 };
        setFeeConfig(newFeeConfig);
    }
    useEffect(_ => { setFeeConfig(Array.isArray(shop.transactionFeeConfig) ? shop.transactionFeeConfig : [{start: 0, fee: 0}]) }, [shop])

    const deleteTier = idx => {
        let newFeeConfig = [...feeConfig];
        newFeeConfig.splice(idx, 1);
        setFeeConfig(newFeeConfig);
    }

    const addTier = idx => {
        let newFeeConfig = [...feeConfig];
        newFeeConfig.splice(idx+1, 0, { start: null, fee: 0 })
        setFeeConfig(newFeeConfig)
    }

    const saveTransactionFeeConfig = _ => {
        // TODO: Validate actual json format
        if (feeConfig) {
            if (!feeConfig.reduce((valid, tier) => valid && (tier.start || tier.start === 0), true)) return toast.error('Fill out all fields')
            feeConfig.sort((a, b) => a.start - b.start)
            console.log(feeConfig)
            axios.put(SHOP_URL + shop._id + '/transactionFeeConfig', feeConfig).then(_ => {
                onSave();
                hide();
            })
        }
    }

    const isPlusDisabled = idx => (!feeConfig[idx].start && idx) || (feeConfig[idx+1] && feeConfig[idx+1].start <= feeConfig[idx].start + 1);
    const configRows = feeConfig.map((tier, idx) =>
        <Form.Row key={idx} className="justify-content-center p-3" style={{borderTop: 'solid 1px'}}>
            <Col lg="1"> <Button disabled={!idx} variant="danger" onClick={_ => deleteTier(idx)}>x</Button> </Col>
            <Col lg="2" className="align-content-center"> <h6 className="px-2 mt-2 font-weight-bold" disabled>Orders >= </h6> </Col>
            <Col lg="2">
                <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control type="number" disabled={!idx} value={idx ? (tier.start || '') : 0} onChange={e => updateConfig(idx, +(e.target.value || 0), tier.fee)}/>
                </InputGroup>
            </Col>
            <Col lg="2" className="align-content-center"> <h6 className="px-2 mt-2 font-weight-bold" disabled>will cost</h6> </Col>
            <Col lg="2">
                <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control type="number" placeholder="0" value={tier.fee || ''} onChange={e => updateConfig(idx, tier.start, +(e.target.value || 0))}/>
                </InputGroup>
            </Col>
            <Col lg="2" className="mt-3 mt-lg-0"> <Button block className="font-weight-bolder" disabled={isPlusDisabled(idx)} variant="success" onClick={_ => addTier(idx)}>+</Button> </Col>
        </Form.Row>
    )

    return <Modal show={true} onHide={hide} centered size="xl">
        <Modal.Header closeButton>
            <Modal.Title>Edit Transaction Fee Configuration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Container>
                {configRows}
            </Container>
            <Modal.Footer>
                <Button variant="secondary" onClick={hide}>Cancel</Button>
                <Button disabled={!feeConfig} variant="primary" onClick={saveTransactionFeeConfig}>Save</Button>
            </Modal.Footer>
        </Modal.Body>
    </Modal>
}
