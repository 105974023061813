import { useState, useEffect } from 'react';
import axios from 'axios';

const INVOICES_URL = '/invoices';

export default function useInvoices() {

    const [invoices, setInvoices] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const refreshInvoices = _ => {
        setInvoices([])
        setRefresh(!refresh);
    }

    useEffect(() => {
        let canceled = false;
        console.log('use invoices effect')
        axios.get(INVOICES_URL).then(({ data }) => {
            if (canceled) return;
            if (data && data.length) setInvoices(data.sort((a, b) => new Date(b.startTime) - new Date(a.endTime)));
            else setInvoices([]);
        });
        return _ => {canceled =  true}; 
    }, [refresh]);

    return [invoices, refreshInvoices]
}
