import React, { useState } from 'react';
import Home from './components/home';
import ErrorBoundary from './components/errorBoundary';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useAuth0 } from './util/auth0Service';
import 'react-toastify/dist/ReactToastify.css';
import 'react-table-6/react-table.css'
import './App.scss';

const LOCAL_URL = 'http://localhost:3939/api/';
const AWS_URL = `${window.origin.split('god.')[0]}api.god.smart-dispensary.com/api/`;

axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? LOCAL_URL : AWS_URL;

toast.configure({
    autoClose: 4000,
    position: 'bottom-right'
})

export default function App() {

    const { loading, getTokenSilently, loginWithRedirect, user } = useAuth0();

    const [ready, setReady] = useState(false);

    if (loading) return <div>Loading...</div>;
    else if (!ready) {
        const lockJsParams = { appState: { hash: window.location.hash }, allowSignUp: false };
        getTokenSilently().then(token => {
            axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` };

            axios.interceptors.response.use(undefined, error => {
                const statusCode = error.response ? error.response.status : null;
                if (statusCode === 401) loginWithRedirect(lockJsParams);
                return Promise.reject(error);
            })

            setReady(true);

        }).catch(e => loginWithRedirect(lockJsParams));
    }

    return (  
        <ErrorBoundary>
            { ready ?
                <div className="App">
                    <Home user={user}/>
                </div>
                :
                <h1>You are not logged in</h1>
            }
        </ErrorBoundary>
    )

}
