import React, { useState, useMemo } from 'react';
import ReactTable from 'react-table-6';
import useOrders from '../hooks/useOrders';
import { DateTime } from 'luxon';


export default function Orders(props) {

    const [startDate, _setStartDate] = useState(DateTime.local().startOf('day'))
    const [endDate, _setEndDate] = useState(DateTime.local().endOf('day'))

    const setStartDate = date => _setStartDate(DateTime.fromISO(date).startOf('day'));

    const setEndDate = date => _setEndDate(DateTime.fromISO(date).endOf('day'));

    const query = useMemo(_ => ({
        done: true,
        startDate: startDate.toJSDate(),
        endDate: endDate.toJSDate()
    }), [startDate, endDate])
    const [orders] = useOrders(query);



    let total = orders.reduce((total, order) => total + order.total, 0)
    const columns = [
        {
            Header: 'Order Id',
            accessor: '_id'
        },
        {
            Header: 'Shop',
            accessor: 'shopId'
        },
        {
            Header: 'Terminal',
            accessor: 'terminalId'
        },
        {
            Header: 'Total',
            accessor: 'total'
        },
        {
            Header: 'Amount Paid',
            accessor: 'amountPaid'
        },
        {
            Header: 'Change',
            accessor: 'change'
        },
        {
            Header: 'Change Given',
            accessor: 'changeDispensed'
        },
        {
            Header: 'Created',
            accessor: 'timestamp'
        },
        {
            Header: 'Updated',
            accessor: 'updated'
        }

    ]
    console.log(startDate.toJSDate())
    console.log("endDate", endDate.toJSDate())
    return (
        <div>
                Start: <input type="date" value={startDate.toISODate()} onChange={e => setStartDate(e.target.value)}/>
                End: <input type="date" value={endDate.toISODate()} onChange={e => setEndDate(e.target.value)}/>
                <h1>
                    Total: <span className="text-success mr-5">${total.toFixed(2)}</span>
                    Count: <span className="text-success mr-5">{orders.length}</span>
                    Average: <span className="text-success">${(total/orders.length).toFixed(2)}</span>

                </h1>
                <ReactTable
                    data={orders} 
                    columns={columns}
                    className='-striped -highlight table'
                    defaultPageSize= {15}
                    pageSizeOptions= {[8, 10, 20]}
                    />
        </div>
    )
}
