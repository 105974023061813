import React from 'react';
import ShopInvoices from './shopInvoices';
import Terminals from './terminals';
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { useAuth0 } from '../util/auth0Service';
import {
    HashRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import HISTORY from '../common/history';
import SecurityFootage from './securityFootage';
import Kiosks from './kiosks';
import Orders from './orders';
import Invoices from './invoices';
import Shops from './shops';
import SalesByProduct from './salesByProduct';
import ProductFeatures from './productFeatures';

function Home() {

    const { logout } = useAuth0();

    return (
        <div className="App">
            <Router hashHistory={HISTORY}>
                <Navbar bg="primary" fixed="top" className="justify-content-between">
                    <Nav>
                        <Link className="text-light mr-3" to="security"> Security Footage</Link>
                        <Link className="text-light mr-3" to="kiosks"> Kiosks</Link>
                        <Link className="text-light mr-3" to="orders"> Orders</Link>
                        <Link className="text-light mr-3" to="invoices"> Invoices</Link>
                        <Link className="text-light mr-3" to="shops"> Shops</Link>
                        <Link className="text-light mr-3" to="salesByProduct">Search Sales</Link>
                        <Link className="text-light mr-3" to="productFeatures">Product Features</Link>
                    </Nav>
                    <Nav className="authButtons float-right">
                        <Button variant="secondary" onClick={() => logout()}>Logout</Button>
                    </Nav>
                </Navbar>
                <div className='mt-5 pt-5'>
                    <Switch>
                        <Route path="/security" render={props => <SecurityFootage {...props} />} />
                        <Route path="/kiosks" render={props => <Kiosks {...props} />} />
                        <Route path="/orders" render={props => <Orders {...props} />} />
                        <Route path="/invoices" render={props => <Invoices {...props} />} />
                        <Route path="/shops" render={props => <Shops {...props} />} />
                        <Route path="/invoices/:id" render={props => <ShopInvoices {...props} />} />
                        <Route path="/terminals/:id" render={props => <Terminals {...props} />} />
                        <Route path="/salesByProduct" render={props => <SalesByProduct {...props} />} />
                        <Route path="/productFeatures" render={props => <ProductFeatures {...props} />} />
                        <Route path="/">
                            <Orders />
                        </Route>
                    </Switch>
                </div>
            </Router>
        </div>
    );
}

export default Home;
