import React, { useState } from 'react';
import ReactTable from 'react-table-6';
import useShops from '../hooks/useShops';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import CreateShopModal from './createShopModal';
import ServiceRateModal from './serviceRateModal';
import TransactionFeeModal from './transactionFeeModal';
import MonthlyLeaseFeeModal from './monthlyLeaseFeeModal';

export default function Shops(props) {

    const [showCreateShop, setShowCreateShop] = useState(false);
    const [shops, refreshShops] = useShops();

    const [editMonthlyLeaseFee, setEditMonthlyLeaseFee] = useState(false);
    const [editServiceRateShop, setEditServiceRateShop] = useState(false);
    const [editTransactionFeeShop, setEditTransactionFeeShop] = useState(false);

    // let total = shops.reduce((total, order) => total + order.total, 0)
    const columns = [
        {
            Header: 'Shop',
            accessor: 'name'
        },
        {
            Header: 'Shop Id',
            accessor: 'shopId',
        },
        {
            Header: 'Lease Fee',
            accessor: 'monthlyLeaseFee',
            Cell: c => <ButtonGroup>
                <Button variant="dark" disabled>${(c.value || 0).toFixed(2)}</Button>
                <Button onClick={_ => setEditMonthlyLeaseFee(c.original)}>Edit</Button>
            </ButtonGroup>
        },
        {
            Header: 'Service Rate',
            accessor: 'serviceRate',
            Cell: c => <ButtonGroup>
                <Button variant="dark" disabled>{((c.value || 0) * 100).toFixed(1)}%</Button>
                <Button onClick={_ => setEditServiceRateShop(c.original)}>Edit</Button>
            </ButtonGroup>
        },
        {
            Header: 'Transaction Fee',
            accessor: 'transactionFeeConfig',
            Cell: c => <ButtonGroup>
                <Button variant="dark" disabled>{c.value ? 'Yes' : 'No'}</Button>
                <Button onClick={_ => setEditTransactionFeeShop(c.original)}>Edit</Button>
            </ButtonGroup>
        }
    ]

    return (
        <div>
            {editMonthlyLeaseFee && <MonthlyLeaseFeeModal shop={editMonthlyLeaseFee} hide={_ => setEditMonthlyLeaseFee(false)} onSave={refreshShops}/>}
            {editServiceRateShop && <ServiceRateModal shop={editServiceRateShop} hide={_ => setEditServiceRateShop(false)} onSave={refreshShops}/>}
            {editTransactionFeeShop && <TransactionFeeModal shop={editTransactionFeeShop} hide={_ => setEditTransactionFeeShop(false)} onSave={refreshShops}/>}
            {showCreateShop && <CreateShopModal hide={_ => setShowCreateShop(false)} onSave={refreshShops}/>}
            <Button onClick={_ => setShowCreateShop(true)}>Create Shop</Button>
            <ReactTable
                data={shops}
                columns={columns}
                className='-striped table'
                defaultPageSize= {15}
                pageSizeOptions= {[10, 15, 20]}
                />
        </div>
    )
}
