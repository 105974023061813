import { useState, useEffect } from 'react';
import axios from 'axios';

const SHOPS_URL = '/shops';

export default function useShops() {

    const [shops, setShops] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const refreshShops = _ => setRefresh(!refresh);

    useEffect(() => {
        let canceled = false;
        console.log('use shops effect')
        axios.get(SHOPS_URL).then(({ data }) => {
            if (canceled) return;
            if (data && data.length) setShops(data);
            else setShops([]);
        });
        return _ => {canceled =  true}; 
    }, [refresh]);

    return [shops, refreshShops]
}
