import React from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from "./util/auth0Service";
import authConfig from './config/AUTH0';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const onRedirectCallback = appState => {
    console.log("here");
};

ReactDOM.render(
    <Auth0Provider domain={authConfig.domain}
                    client_id={authConfig.clientId}
                    audience={authConfig.audience}
                    cacheLocation="localstorage"
                    redirect_uri={window.location.origin}
                    onRedirectCallback={onRedirectCallback}>
        <App />
    </Auth0Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
