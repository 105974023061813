import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import Loading from './loading';

const SHOPS_URL = '/shop';

const ShopInvoices = props => {


  const shopId = props.match.params.id;
  const [invoices, setInvoices] = useState(null);

  useEffect(() => {
    const shopUrl = SHOPS_URL + '/' + shopId + '/invoices';
    axios.get(shopUrl).then(({ data }) => {
      setInvoices(data);
    }).catch(console.error)
  }, [shopId]);

  return (
    <HomeContainer>
        { invoices ?
          <ShopsContainer>
              <TopBar>
                <h1>Shop Invoices - {invoices[0].name}</h1>
              </TopBar>
              <Invoices>
              <table>
                <tbody>
                    <tr>
                        <td className="reduce" style={{color: '#000000',}}>Number of Orders</td>
                        <td className="reduce" style={{color: '#000000',}}>Total Sales</td>
                        <td style={{color: '#000000',}}>Total Owed</td>
                        <td style={{color: '#000000',}}>Paid</td>
                    </tr>
                { invoices.map(invoice => {
                  console.log(invoice);
                  return (
                        <tr key={invoice._id}>
                          <td className="reduce">{invoice.numberOfOrders}</td>
                          <td className="reduce">{invoice.totalSales}</td>
                          <td>{parseFloat(parseInt(invoice.totalOwed)).toFixed(2)}</td>
                          <td>{invoice.paid ? <span className="green"></span> : <span className="red"></span> }</td>
                        </tr>
                  )}
                )}
                </tbody>  
              </table>
            </Invoices>
          </ShopsContainer>
          :
          <Loading />
        }
    </HomeContainer>
  );
}

export default ShopInvoices;

const HomeContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  padding-top: 60px;
`;

const ShopsContainer = styled.div`
  display: block;
  width: 70%;
  margin: 0 auto;
  font-size: 24px;
  padding: 20px;
  text-align: left;
  @media only screen and (max-width: 400px) {
    width: 90%;
  }
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  padding: 20px 0px;
  h1 {
      font-size: 35px;
      width: 100%;
      margin: 0 auto;
  }
`;

const Invoices = styled.div`
  width: 80%;
  margin: 0 auto;
  text-align: center;
  table {
    width: 100%;
    table-layout: fixed;
    background: rgba(38, 166, 91, 1);
    border-radius: 5px;
    padding: 20px 10px;
  }

  td, th {
    border: 0px solid #dddddd;
    text-align: center;
    padding: 8px;
    color: #FFFFFF;
    padding: 15px 5px;
    font-weight: bold;
    font-size: 18px;
  }

  tr {
      margin-bottom: 5px;
  }

  td .green {
    width: 10px;
    height: 10px;
    display: block;
    background-color: green;
    text-align: center;
    margin: 0 auto;
    border-radius: 50%;
}
  }

  td .red {
    width: 10px;
    height: 10px;
    display: block;
    background-color: red;
    text-align: center;
    margin: 0 auto;
    border-radius: 50%;
}
  }

  tr:hover {
    transform: scale(1.01);
  }

  a{
    border: 0;
    padding: 10px 15px;
    color:  rgba(38, 166, 91, 1);
    border-radius: 5px;
    font-weight: bold;
    background: #FFFFFF;
    text-decoration: none;
  }
  a:hover {
    cursor: pointer;
    text-decoration: none;
  }
`;
