import { useState, useEffect } from 'react';
import axios from 'axios';

const TERMINALS_URL = '/terminals';

export default function useTerminals(query) {

    const [terminals, setTerminals] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const refreshTerminals = _ => setRefresh(!refresh)

    useEffect(() => {
        axios.get(TERMINALS_URL).then(({ data }) => {
            if (data && data.length) {
                setTerminals(data);
            } else {
                setTerminals([]);
            }
        })
    }, [refresh]);


    return [terminals, refreshTerminals]
}
