import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Loading from './loading';
import styled from 'styled-components';

const TERMINAL_URL = '/terminals';



export default function Terminals(props) {

    const [terminals, setTerminals] = useState(null);

    useEffect(() => {
        const shopId = props.match.params.id;
        const terminalUrl = TERMINAL_URL + '/' + shopId;
        axios.get(terminalUrl).then(resp => setTerminals(resp.data)).catch(err => {
            console.error(err);
            // toast.error('Inventory Database not responding');
        });
    }, [props.match.params.id]);

    return (
        <div>
            {terminals ?
                <TerminalContainer>
                        <div className="terCon">
                            <h2 style={{fontWeight: 'bold'}}>Terminal Name</h2>
                            <h3 className="reduce" style={{fontWeight: 'bold'}}>Terminal ID</h3>
                            <p>Terminal Status</p>
                        </div>
                        { terminals.map(terminal => {
                            return (
                                <div className="terminal" key={terminal._id}>
                                    <h2>{terminal.name}</h2>
                                    <h3 className="reduce">{terminal._id}</h3>
                                    <p>{terminal.status}</p>
                                </div>
                            )}
                        )}
                </TerminalContainer>
                :
                <Loading />
            }
        </div>
    )
}

const TerminalContainer = styled.div`
    .terCon {
        display: inline-block;;
        text-align: left;
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 15px;
        padding-bottom: 15px;
        width: 90%;
        margin: 10px;
        font-weight: bold;
        background: #FFFFFF;
        box-shadow: 0 0 5px rgba(0,0,0,0.4);
        border-radius:15px;
    }
    .terminal {
        display: inline-block;;
        text-align: left;
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 15px;
        padding-bottom: 15px;
        width: 90%;
        margin: 10px;
        box-shadow: 0 0 5px rgba(0,0,0,0.4);
        background: #FFFFFF;
        border-radius:15px;
    }
    h2 {
        display: inline-block;
        width: 30%;
        text-align: left;
        font-size: 25px;
    }
    h3 {
        display: inline-block;
        width: 50%;
        text-align: left;
        font-size: 25px;
    }
    p {
        display: inline-block;
        width: 18%;
        text-align: center;
        font-size: 25px;
    }
    @media only screen and (max-width: 400px) {
        .terCon {
            width: 70%;
        }
        .terminal {
            width: 70%;
        }
      }
      p {
          float: right;
          width: 30%;
      }
      h3 {
          width: 30%;
      }
`;
