import React, { useState } from 'react';
import ReactTable from 'react-table-6';
import useInvoices from '../hooks/useInvoices';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { toast } from 'react-toastify';

const INVOICES_URL = '/invoices/';

const addVals = vals => vals.reduce((total, val) => total + val, 0);

const togglePaid = async invoice => {
    if (invoice._id) {
        await axios.get(INVOICES_URL + invoice._id + (invoice.paid ? '/unpay' : '/pay'));
    }
}

export default function Invoices(props) {
    console.log('hey', props)

    const [markAsPaid, setMarkAsPaid] = useState(false);
    const [invoices, reloadInvoices] = useInvoices();
    const [disabled, setDisabled] = useState(false);
    const afterMarkPaid = _ => {
        reloadInvoices();
        setMarkAsPaid(false);
        setDisabled(false);
    }

    const sendReminder = invoiceId => axios.get(INVOICES_URL + invoiceId + '/remind').catch(_ => toast.error("Error sending invoice reminder"))

    // let total = invoices.reduce((total, order) => total + order.total, 0)
    const columns = [
        {
            Header: 'Shop',
            accessor: 'name',
            aggregate: vals => vals[0]
        },
        {
            Header: 'Month',
            id: 'date',
            accessor: i => new Date(i.startTime).toLocaleString('default', { month: 'long', year: 'numeric' })
        },
        {
            Header: 'Paid',
            accessor: 'paid',
            Cell: c => <div onClick={_ => !c.subRows && !disabled && setMarkAsPaid(c.row)} className={c.value ? 'bg-success': 'bg-danger'}> {c.value ? 'yes' : 'no'}</div>,
            aggregate: vals => vals.reduce((allPaid, invoicePaid) => allPaid && invoicePaid, true)
        },
        {
            Header: 'Amount Paid',
            id: 'totalPaid',
            accessor: i => i.totalPaid || 0,
            aggregate: addVals
        },
        {
            Header: 'Total Owed',
            id: 'totalOwed',
            accessor: i => i.paid ? 0 : i.totalOwed,
            aggregate: addVals
        },
        {
            Header: 'Num Orders',
            accessor: 'numberOfOrders',
            aggregate: addVals
        },
        {
            Header: 'Invoice Id',
            accessor: '_id'
        },
        {
            Header: 'Send Reminder',
            accessor: i => i._id,
            aggregate: _ => '',
            id: 'reminder',
            Cell: c => c.value ? <Button disabled={disabled} onClick={_ => sendReminder(c.value)}>Send</Button> : <div></div>
        }
    ]
    
    return (
        <div>
                {/* <h1>
                    Total: <span className="text-success mr-5">${total.toFixed(2)}</span>
                    Count: <span className="text-success mr-5">{invoices.length}</span>
                    Average: <span className="text-success">${(total/invoices.length).toFixed(2)}</span>

                </h1> */}
                <ReactTable
                    data={invoices} 
                    columns={columns}
                    className='-striped -highlight table'
                    defaultPageSize= {15}
                    pageSizeOptions= {[5, 15, 25]}
                />
                <Modal show={!!markAsPaid} onHide={_ => setMarkAsPaid(false)}>
                    <Modal.Header closeButton>
                        <h3>
                            Mark As {markAsPaid.paid ? <span className="font-weight-bold text-danger"> Not Paid</span>: <span className="font-weight-bold text-success"> Paid</span>}?
                        </h3>
                    </Modal.Header>
                    <Modal.Footer>
                        <Button disabled={disabled} onClick={_ => togglePaid(markAsPaid).then(afterMarkPaid)}>Confirm</Button>
                    </Modal.Footer>
                </Modal>
        </div>
    )
}
